import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FactoryIcon from '@mui/icons-material/Factory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useNavigate } from "react-router-dom";

export default function NavMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const timeSheetRoles = ["Service_TimeSheet_Manager", "Service_TimeSheet_User", "SI_TimeSheet_Manager", "SI_TimeSheet_User", "Payroll_Processors"];
  const serviceTSUserRole = ["Service_TimeSheet_User"];
  const adminRole = ["Admin"];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRedirect = (link) => {
    navigate(`/${link}`);
    setOpen(false);
  }

  return (
    <div>
      <Button style={{ color: 'white' }} onClick={toggleDrawer(true)}><MenuIcon /></Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            <ListItem key={0} disablePadding>
              <ListItemButton onClick={() => handleRedirect('projectManagement')}>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary={'Project Management'} />
              </ListItemButton>
            </ListItem>

            <ListItem key={1} disablePadding>
              <ListItemButton onClick={() => handleRedirect('Manufacturing')}>
                <ListItemIcon>
                  <FactoryIcon />
                </ListItemIcon>
                <ListItemText primary={'Manufacturing'} />
              </ListItemButton>
            </ListItem>

            <ListItem key={2} disablePadding>
              <ListItemButton onClick={() => handleRedirect('BayAssignment')}>
                <ListItemIcon>
                  <WarehouseIcon />
                </ListItemIcon>
                <ListItemText primary={'Bay Assignment'} />
              </ListItemButton>
            </ListItem>

            <ListItem key={3} disablePadding>
              <ListItemButton onClick={() => handleRedirect('Device')}>
                <ListItemIcon>
                  <AnalyticsIcon />
                </ListItemIcon>
                <ListItemText primary={'System Lookup'} />
              </ListItemButton>
            </ListItem>

            <ListItem key={3} disablePadding>
              <ListItemButton onClick={() => handleRedirect('WIP')}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={'WIP'} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}