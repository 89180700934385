import React from "react";
import { Typography, Container } from "@mui/material";
import addTab from "../res/mp4/addTab.mp4";
import addDevice from "../res/mp4/addDevice.mp4";
import airgas from "../res/mp4/airgas.mp4";
import deleteItems from "../res/mp4/deleteItems.mp4";
import networkInfo from "../res/mp4/networkInfo.mp4";
import notes from "../res/mp4/notes.mp4";
import internalInfo from "../res/mp4/internalInfo.mp4";
import navToMds from "../res/mp4/navToMds.mp4";
import siteInfo from "../res/mp4/siteInfo.mp4";
import timSheetNavToTimesheet from "../res/mp4/timeSheetNavToTimesheet.mp4";
import timeSheetClockIn from "../res/mp4/timeSheetClockIn.mp4";
import timeSheetViewPTORequests from "../res/mp4/timeSheetViewPTOReqeusts.mp4";
import timeSheetSubmitPTO from "../res/mp4/timeSheetSubmitPTO.mp4";

function PatchNotes() {
    const nestedBullets = [
        "Major Updates",
        [
            "•Added Timesheet interface for Service and Systems Integration users",
            <video src={timSheetNavToTimesheet} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Added Device Lookup Page",
            "•Added Master Device Sheet for the configuration management info",
            <video src={navToMds} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
        ],
        "Additional Updates",
        [
            "•Ability to add/edit the network data",
            <video src={networkInfo} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Master Device Sheet Notes section has ability to mark notes as 'complete'",
            <video src={notes} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Users can show all notes or view only the notes that are not yet 'complete'",
            "•Ability to add/edit Programmer, Install Tester, and Timer Test",
            <video src={internalInfo} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Ability to add/edit the airgas vendor data",
            <video src={airgas} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Templates use the product type and riser count to automatically pre-populate the device configuration information where possible",
            <video src={addTab} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            <br />,
            <video src={addDevice} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Ability to add/edit/remove additional risers, configuration information, and entire device components",
            <video src={deleteItems} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Ability to clock in and out, tracking hours worked in real time",
            "•Ability to track and edit worked hours on a weekly basis",
            <video src={timeSheetClockIn} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0" }} />,
            "•Ability to submit electronic PTO requests, track PTO approval status, and remaining PTO",
            <video src={timeSheetSubmitPTO}  autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0"}} />,
            "•Managers can review and approve/deny PTO requests and Timesheet submissions",
            <video src={timeSheetViewPTORequests} autoPlay loop muted style={{ width: "100%", borderRadius: "2%", margin: "20px 0" }} />,
            "•Payroll Processors can review all employee timesheets, PTO requests, and PTO banks"
        ],
        "Changes",
        [
            "•The document file upload selection functionality has been updated to include additional file types"
        ],
    ];

    return (
        <>
            <Typography variant="h3" textAlign={"center"} style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                🔥 Release Notes 11/14/24 🔥
            </Typography>
            <ListComponent items={nestedBullets} />
        </>
    );
  }

  const ListComponent = ({ items }) => {
    if (!Array.isArray(items)) {
        return typeof items === "string" ? (
            <Typography component="li" style={{ marginBottom: "10px" }}>
                {items}
            </Typography>
        ) : (
            <div style={{ marginBottom: "20px" }}>{items}</div>
        );
    }

    return (
        <Container maxWidth="md" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <Typography component="ul" style={{ listStyleType: "none", paddingLeft: 0 }}>
                {items.map((item, index) => (
                    <ListComponent key={index} items={item} />
                ))}
            </Typography>
        </Container>
    );
};

export default PatchNotes;


